import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useEffect } from 'react';

const Widget = ({ type, product, selectedVariant }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const host = selectedChannel.url;
  const common = {
    id: 'lipscore-' + type,
    className: 'lipscore-widget',
    productName: product?.name,
    brand: product?.customFields?.filter(item => item.key === 'varumarke')[0]
      ?.stringValue,
    productId: product?.id,
    productSku: product?.articleNumber,
    variantId: product?.articleNumber,
    variantName: product?.articleNumber,
    productUrl: product?.primaryRoute.path,
    description: product?.description,
    imageUrl:
      host +
      (product?.images && product.images.length && product.images[0].url),
    price: product?.price.price,
    stockStatus: product?.stockStatus?.text,
    currency: selectedChannel.currency.name
  };
  switch (type) {
    case 'rating':
      return (
        <div
          id="lipscore-rating"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-availability={common.stockStatus}
          data-ls-price-currency={common.currency}
          data-ls-variant-id={common.variantId}
          data-ls-variant-name={common.variantName}
          data-ls-sku={common.productSku}
          data-ls-category="[Product_category-used_for_SEO]"
          data-ls-mpn="[Product_MPN-used_for_SEO]"
          data-ls-gtin="[Product_GTIN-used_for_SEO]"
          data-ls-readonly="true"
        />
      );
    case 'rating-small':
      return (
        <span
          className="lipscore-rating-small"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        ></span>
      );
    case 'review-list':
      return (
        <div
          id="lipscore-review-list"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        ></div>
      );
    case 'review-count':
      return (
        <span
          className="lipscore-review-count"
          data-ls-product-name={common.productName}
          data-ls-brand={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
          data-ls-image-url={common.imageUrl}
          data-ls-description={common.description}
          data-ls-price={common.description}
          data-ls-price-currency="SEK"
          data-ls-availability={common.stockStatus}
        ></span>
      );
    case 'service-small':
      return (
        <div
          className="lipscore-service-review-badge-small-short lipscore-no-border lipscore-no-separator"
          data-ls-widget-height="45px"
          data-ls-widget-width="300px"
        ></div>
      );
    default:
      return (
        <div
          id="lipscore-rating"
          data-ls-product-name={common.productName}
          data-ls-brand-name={common.brand}
          data-ls-product-id={common.productId}
          data-ls-product-url={host + common.productUrl}
        />
      );
  }
};

const LipscoreWidget = ({ type, product = null }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.lipscore)
      window.lipscore.initWidgets();
  });

  return <Widget type={type} product={product} />;
};

export default LipscoreWidget;
